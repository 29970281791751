/*
$(document).ready(function() {
  var point = $('.ankerpoint')
  var headernav = $('.dhsv_vc_anker')
  var ankernav = $('.ankernav')
  var i = 0
  point.each(function() {
    i++
    var e = $(this)
    var eID = e.attr('id')
    var eTitle = e.data('ankertitle')

    headernav.append(
      '<li><a class="link link-default" href="#' + eID + '"></a></li>'
    )
    ankernav.addClass('hidenav')
    ankernav
      .find('ul')
      .append(
        '<li><a href="#' +
        eTitle +
        '" style="transition-delay: ' +
        i / 25 +
        's"><span>' +
        eTitle +
        '</span></a></li>'
      )
  })
})

$(window).on('scroll', ankerScroll)

function ankerScroll() {
  var scrolled = $(window).scrollTop()
  var winH = $(window).height()
  var point = $('.ankerpoint')
  var nav = $('.ankernav')
  var navPos = $('.navpos')
  var end = $('.ankernav_end')

  if (end.length) {
    if (
      scrolled + winH / 2 >= navPos.offset().top &&
      scrolled + winH / 2 <= end.offset().top
    ) {
      nav.removeClass('hidenav')
    } else {
      nav.addClass('hidenav')
    }
  } else if (navPos.length) {
    if (scrolled + winH / 2 >= navPos.offset().top) {
      nav.removeClass('hidenav')
    } else {
      nav.addClass('hidenav')
    }
  }

  point.each(function(index) {
    var e = $(this)
    var eID = e.data('ankertitle')
    var eTop = e.offset().top

    if (
      index !== point.length - 1 &&
      point.eq(index + 1).offset().top <= scrolled + winH / 3
    ) {
      $('[href="#' + eID + '"]')
        .parent()
        .removeClass('active')
    } else {
      if (eTop <= scrolled + winH / 3) {
        $('[href="#' + eID + '"]')
          .parent()
          .addClass('active')
      } else {
        $('[href="#' + eID + '"]')
          .parent()
          .removeClass('active')
      }
    }
  })
}
*/

$(document).ready(function() {
  var point = $('.ankerpoint')
  //var headernav = $('.dhsv_vc_anker')
  var ankernav = $('.ankernav')
  var i = 0

  point.each(function() {
    i++
    var e = $(this)
    //var eID = e.attr('id')
    var eTitle = e.data('ankertitle')

    /*
    headernav.append(
      '<li><a class="link link-default" href="#' + eID + '"></a></li>'
    )
    */
    ankernav.addClass('hidenav')
    ankernav
      .find('ul')
      .append(
        '<li class="ankernav-li"><a class="ankernav-item" href="#' +
          eTitle +
          '" style="transition-delay: ' +
          i / 25 +
          's"></a></li>'
      )
  })
})

//aniline
/*
$(window).load(function() {
  var screenCenter = $(window).height() / 2
  var ankerH = $('.ankernav').outerHeight()
  var maxLineH = screenCenter + ankerH / 2
  $(window).on('scroll', function() {
    var activeElem = $('section.active').attr('id')
    // eslint-disable-next-line no-console
    //console.log(activeElem)
    var elem = document.getElementById(activeElem)
    if (elem !== null) {
      var coords = elem.getBoundingClientRect()
      var coordsbottom = coords.bottom
      var linePosition = coordsbottom - maxLineH
      var lineH = linePosition - 30
      $('.aniline').each(function(index, element) {
        var aniline = $(element)
        aniline.find('div').css('height', lineH)
      })
    }
  })
})
*/

$(window).on('load', function() {
  var screenCenter = $(window).height() / 2
  var ankerH = $('.ankernav').outerHeight()
  var maxLineH = screenCenter + ankerH / 2
  $(window).on('scroll', function() {
    var activeElem = $('section.active').attr('id')
    // eslint-disable-next-line no-console
    //console.log(activeElem)
    var elem = document.getElementById(activeElem)
    if (elem !== null) {
      var coords = elem.getBoundingClientRect()
      var coordsbottom = coords.bottom
      var linePosition = coordsbottom - maxLineH
      var lineH = linePosition - 30
      $('.aniline').each(function(index, element) {
        var aniline = $(element)
        aniline.find('div').css('height', lineH)
      })
    }
  })
})
