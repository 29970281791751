function getSliderSettings() {
  return {
    dots: true,
    arrows: true,
    slidesToShow: 1,
    cssEase: 'ease',
    variableWidth: false,
    speed: 800,
    fade: true,
    autoplay: true,
    autoplaySpeed: 2000,
  }
}

$('.dhsv_vc_testimonials_slider').slick(getSliderSettings())

$('.dhsv_vc_testimonials_slider .testimonials__content .btn-container').on(
  'click',
  function() {
    if (
      $(this)
        .closest('.testimonials__content')
        .hasClass('active-accordion')
    ) {
      $(this)
        .closest('.testimonials__content')
        .removeClass('active-accordion')
        .css('height', '300px')
    } else {
      var height = $(this)
        .closest('.testimonials__content--content')
        .outerHeight()
      $(this)
        .closest('.testimonials__content')
        .addClass('active-accordion')
        .css('height', height + 45)
    }
  }
)
