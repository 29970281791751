//Ajax load more
/*eslint-disable*/
$('.loadmore-video').click(function() {
  $(this).text('Loading...')
  var data = {
    action: 'loadmore_video',
    query: true_posts, //eslint-disable-line
    page: current_page, //eslint-disable-line
  }
  $.ajax({
    url: ajaxurl, //eslint-disable-line
    data: data,
    type: 'POST',
    success: function(data) {
      if (data) {
        $('.loadmore-video')
          .text('Show more')
          .before(data)
        current_page++ //eslint-disable-line
        if (current_page == max_pages) $('.loadmore-video').remove() //eslint-disable-line
      } else {
        $('.loadmore-video').remove()
      }
    },
  })
})

$(document).on('click', '.videos_loop__item', function() {
  $('.videos_popup').fadeIn()
  var href = 'https://www.youtube.com/embed/'
  var $id = $(this).data('video')
  href += $id + '?autoplay=1'
  $('.videos_popup iframe').attr('src', href)
  $('body').addClass('no-scroll')
})

$('.videos_popup__content').on('click', '.close_video', function() {
  $(this)
    .closest('.videos_popup')
    .fadeOut()
    .find('iframe')
    .attr('src', '')
  $('body').removeClass('no-scroll')
})

$('.videos_popup').on('click', function() {
  $(this)
    .find('iframe')
    .attr('src', '')
  $(this).fadeOut()
  $('body').removeClass('no-scroll')
})

function VideoHeight() {
  $('.videoHeight').matchHeight()
}

/*
$(window).load(function() {
  VideoHeight()
})
*/

$(window).on('load', function() {
  VideoHeight()
})

$(window).resize(function() {
  VideoHeight()
})
