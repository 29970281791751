function setvideosrc() {
  var winwidth = $(window).width()
  var videosrc = $('#bgvid').attr('data-videosrc')
  if (winwidth >= 769 && videosrc != undefined) {
    //var videosrc = $('#bgvid').attr('data-videosrc')
    $('#bgvid source').attr('src', videosrc)
    $('#bgvid')[0].load()
  } else {
    $('#bgvid source').attr('src', '')
  }
}

$(document).ready(function() {
  setvideosrc()
})

$(window).resize(function() {
  setvideosrc()
})
