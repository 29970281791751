import $ from 'jquery'
import 'slick-carousel'

$(document).ready(() => {
  $('.single-image-slider').slick({
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    slide: '.image-slide',
    rows: 0,
  })
})
