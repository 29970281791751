function init_vacancy_box() {
  var dhsv_vc_vacancy_box_height = 0
  var dhsv_vc_vacancy_box_height_elm = 0
  var dhsv_vc_vacancy_box_height2 = 0
  var dhsv_vc_vacancy_box_height_elm2 = 0
  $('.dhsv_vc_vacancies a .vacancy .vacancy__thumbnail').each(function() {
    dhsv_vc_vacancy_box_height_elm2 = $(this).outerHeight()
    if (dhsv_vc_vacancy_box_height_elm2 > dhsv_vc_vacancy_box_height2) {
      dhsv_vc_vacancy_box_height2 = dhsv_vc_vacancy_box_height_elm2
    }
  })
  $('.dhsv_vc_vacancies a .vacancy .vacancy__thumbnail .vacancy__title').each(
    function() {
      dhsv_vc_vacancy_box_height_elm = $(this).outerHeight()
      if (dhsv_vc_vacancy_box_height_elm > dhsv_vc_vacancy_box_height) {
        dhsv_vc_vacancy_box_height = dhsv_vc_vacancy_box_height_elm
      }
    }
  )
  $('.dhsv_vc_vacancies a .vacancy .vacancy__thumbnail .vacancy__title').each(
    function() {
      $(this).css('height', dhsv_vc_vacancy_box_height + 'px')
    }
  )

  $('.dhsv_vc_vacancies .send_vc a').css(
    'height',
    dhsv_vc_vacancy_box_height2 + 'px'
  )
}

$(window).on('load', function() {
  init_vacancy_box()
})

/*
$(window).load(function() {
  init_vacancy_box()
})
*/
