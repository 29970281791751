//Ajax pricing
/*eslint-disable*/
$('.weiter-btn').click(function() {
  $(this).text('Loading...')
  var data = {
    action: 'pricing',
  }
  $.ajax({
    url: ajaxurl, //eslint-disable-line
    data: data,
    type: 'POST',
    success: function(data) {
      if (data) {
        var selectorParent = $(
          '.form-list input[name=selector]:checked'
        ).parent()
        var text = selectorParent[0].attributes[1].value
      } else {
        alert(Error)
      }
    },
  })
})
