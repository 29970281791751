//Ajax load more
$('.loadmore-list').click(function() {
  $(this).text('Loading...')
  var data = {
    action: 'loadmore_research',
    query: true_posts, //eslint-disable-line
    page: current_page, //eslint-disable-line
  }
  $.ajax({
    url: ajaxurl, //eslint-disable-line
    data: data,
    type: 'POST',
    success: function(data) {
      if (data) {
        $('.loadmore-list')
          .text('Load more')
          .before(data)
        current_page++ //eslint-disable-line
        if (current_page == max_pages) $('.loadmore-list').remove() //eslint-disable-line
      } else {
        $('.loadmore-list').remove()
      }
    },
  })
})
