// import external dependencies
import 'jquery'

// import everything from autoload
import './autoload/_bootstrap.js'; import './autoload/_matchheight.js'; import './autoload/_material-icons.js'; import './autoload/_parallax.js'; import './autoload/_scrollreveal.js'; import './autoload/_slick.js' // eslint-disable-line

// import vc-elements javascript files
import '../vc_elements/_new/main.js'; import '../vc_elements/anker/main.js'; import '../vc_elements/audio/main.js'; import '../vc_elements/blog_grid/main.js'; import '../vc_elements/blog_slider/main.js'; import '../vc_elements/button/main.js'; import '../vc_elements/consultations_list/main.js'; import '../vc_elements/deals/main.js'; import '../vc_elements/events/main.js'; import '../vc_elements/history/main.js'; import '../vc_elements/isin_list/main.js'; import '../vc_elements/latest_video/main.js'; import '../vc_elements/latest_videos_list/main.js'; import '../vc_elements/location/main.js'; import '../vc_elements/pdf/main.js'; import '../vc_elements/press_releases/main.js'; import '../vc_elements/pricing/main.js'; import '../vc_elements/research_articles_list/main.js'; import '../vc_elements/research_slider_articles/main.js'; import '../vc_elements/research_slider/main.js'; import '../vc_elements/shareholders/main.js'; import '../vc_elements/single_image_slider/main.js'; import '../vc_elements/statistics/main.js'; import '../vc_elements/team_grid/main.js'; import '../vc_elements/team/main.js'; import '../vc_elements/teaser_video/main.js'; import '../vc_elements/template_element/main.js'; import '../vc_elements/testimonials_slider/main.js'; import '../vc_elements/vacancies/main.js'; import '../vc_elements/video/main.js' // eslint-disable-line

// import react habitat and react components
import './init/react'

// import local dependencies
import Router from './util/Router'
import common from './routes/common'
import home from './routes/home'
import aboutUs from './routes/about'

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
})

// Load Events
jQuery(document).ready(() => routes.loadEvents())
