$('.deal-display h6:nth-child(1)').addClass('active-heading')
$('body').on('click', '.deal-rows', function() {
  $(this)
    .toggleClass('active-row')
    .find('.deal-display h6:not(".active-heading")')
    .not(':first-of-type')
    .slideToggle()
  $(this)
    .toggleClass('active-box')
    .find('.description-box')
    .slideToggle()
})

function deals_active_heading() {
  $('.deal-display h6:nth-child(1)').addClass('active-heading')
  $('body').on('click', '.deal-display h6:nth-child(1)', function() {
    $(this).addClass('active-heading')
  })
}

//deals_active_heading()

//Ajax load more
$('.loadmore-deals').click(function() {
  $(this).text('Loading...')
  var data = {
    action: 'loadmore_deals',
    query: true_posts, //eslint-disable-line
    page: current_page, //eslint-disable-line
  }
  $.ajax({
    url: ajaxurl, //eslint-disable-line
    data: data,
    type: 'POST',
    success: function(data) {
      if (data) {
        $('.loadmore-deals')
          .text('Load more')
          .before(data)
        current_page++ //eslint-disable-line
        if (current_page == max_pages) $('.loadmore-deals').remove() //eslint-disable-line
      } else {
        $('.loadmore-deals').remove()
      }
      deals_active_heading()
    },
  })
})
