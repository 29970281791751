$('.team-filter__filter a').click(function(e) {
  e.preventDefault()
  var a = $(this).attr('href')
  a = a.substr(1)
  $('.team-content .col').each(function() {
    if (!$(this).hasClass(a) && a != 'all') $(this).addClass('hide')
    else $(this).removeClass('hide')
  })
})

$('.teamHeight').matchHeight({
  byRow: false,
})
