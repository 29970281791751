//Ajax load more
$('.loadmore-blog').click(function() {
  $(this).text('Loading...')
  var data = {
    action: 'loadmore_blog',
    query: true_posts, //eslint-disable-line
    page: current_page, //eslint-disable-line
  }
  $.ajax({
    url: ajaxurl, //eslint-disable-line
    data: data,
    type: 'POST',
    success: function(data) {
      if (data) {
        $('.loadmore-blog')
          .text('Load more')
          .before(data)
        current_page++ //eslint-disable-line
        if (current_page == max_pages) $('.loadmore-blog').remove() //eslint-disable-line
      } else {
        $('.loadmore-blog').remove()
      }
    },
  })
})

$(document).ajaxComplete(function() {
  $.when('AjaxLoadMore.loadmore_blog').done(function() {
    setTimeout(function() {
      $.fn.matchHeight._apply('.sage-blog-grid-wrapper .blog-grid .blog p')
    }, 100)
  })
})

function init_blog_box() {
  var dhsv_vc_blog_box_height = 0
  var dhsv_vc_blog_box_height_elm = 0
  $('.blog-grid .blog a').each(function() {
    dhsv_vc_blog_box_height_elm = $(this).outerHeight()
    if (dhsv_vc_blog_box_height_elm > dhsv_vc_blog_box_height) {
      dhsv_vc_blog_box_height = dhsv_vc_blog_box_height_elm
    }
  })
  $('.blog-grid .blog a').each(function() {
    $(this).css('height', dhsv_vc_blog_box_height + 'px')
  })
}
$(document).ready(function() {
  init_blog_box()
})
