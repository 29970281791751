var autoplay = '?autoplay=1'
if ($('.video').length) {
  var src = $('.video iframe')[0].src
}
$('.video_play').on('click', function() {
  if ($('.video').length) {
    $('.video iframe')[0].src += autoplay
  }
  if ($('#background_video').length) {
    $('video')
      .get(0)
      .play()
  }
  $('.video_thumbnail').fadeOut()
  $('.video_play').fadeOut()
  $('.video_block').addClass('active')
})

$('.video_stop').on('click', function() {
  if ($('#background_video').length) {
    $('video')
      .get(0)
      .pause()
  }
  if ($('.video').length) {
    $('.video iframe').attr('src', src)
    $('.video_thumbnail').fadeIn()
  }
  $('.video_block').removeClass('active')
  $('.video_play').fadeIn()
})
